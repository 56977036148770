import dayjs from 'dayjs';

import {
  BoardCategory,
  GetBoardCategoriesQuery,
  GetBoardCommentBelongedBoardCommentsQuery,
  GetBoardPostQuery,
  GetCommunityUserQuery,
  GetMyBoardCommentQuery,
  GetMyBoardPostCursorPageQuery,
  GetNewestBoardPostCursorPageQuery,
  GetRecommendedPopularBoardPostsQuery,
} from '@/types/bff/gql-schema';
import {
  BOARD_POST_TAG_TYPE,
  BoardComment,
  BoardPostDetail,
  BoardPostVo,
  CommunityBottomSheetCategoriesDto,
  CommunityUser,
  CommunityUserProfile,
  LatestHotBoardPosts,
  MyBoardComment,
  NewestBoardPostDto,
  NewestBoardPostTag,
} from '@/types/community';
import { displayedAt, getBoardPostTagLabel } from '@/utils/format';
import { shuffleLatestHotBoardPosts } from '@/utils/sort';

/**
 * BFF Parse
 */

// 카테고리
const boardCategoriesParser = (data: GetBoardCategoriesQuery) => {
  return data.boardCategories;
};

const boardCategoriesBottomSheetParser = (data: GetBoardCategoriesQuery): Array<CommunityBottomSheetCategoriesDto> => {
  return data.boardCategories.map((category) => {
    return {
      boardCategoryId: category.boardCategoryId,
      name: category.name,
    };
  });
};

// 홈 인기/추천 글 목록
const latestHotBoardPostsParser = (data: GetRecommendedPopularBoardPostsQuery) => {
  return shuffleLatestHotBoardPosts(data.recommendedPopularBoardPosts);
};

// 커뮤니티 글 태그 생성
export const getBoardPostTags = (post: NewestBoardPostDto, isCategoryFilter: boolean): Array<NewestBoardPostTag> => {
  const category = {
    type: BOARD_POST_TAG_TYPE.DEFAULT,
    label: post.boardCategory.name,
  };

  if (!post.isPopular && !post.isRecommended && isCategoryFilter) {
    return [];
  }
  if (post.isPopular || post.isRecommended) {
    const tagType = post.isPopular ? BOARD_POST_TAG_TYPE.POPULAR : BOARD_POST_TAG_TYPE.RECOMMENDED;
    const tag = {
      type: tagType,
      label: getBoardPostTagLabel(tagType),
    };
    if (isCategoryFilter) {
      return [tag];
    }
    return [tag, category];
  }
  return [category];
};

// 커뮤니티 글 목록 parser
const newestBoardPostParser = (data: GetNewestBoardPostCursorPageQuery, isCategoryFilter = false) => {
  return data.newestBoardPostCursorPage.elements.map((post) => {
    return {
      ...post,
      formattedCreatedAt: displayedAt(post.createdAt),
      tags: getBoardPostTags(post, isCategoryFilter),
    };
  });
};

// 커뮤니티 내 글 목록 parser
const myBoardPostParser = (data: GetMyBoardPostCursorPageQuery, isCategoryFilter = false) => {
  return data.myBoardPostCursorPage.elements.map((post) => {
    return {
      ...post,
      formattedCreatedAt: displayedAt(post.createdAt),
      tags: getBoardPostTags(post, isCategoryFilter),
    };
  });
};

// 커뮤니티 글 상세페이지 parser
const boardPostDetailParser = (data: GetBoardPostQuery) => {
  return {
    ...data.boardPost,
    formattedCreatedAt: displayedAt(data.boardPost.createdAt),
    boardComments: data.boardPost.boardComments.map((comment) => {
      return {
        ...comment,
        formattedCreatedAt: displayedAt(comment.createdAt),
      };
    }),
  };
};

// 커뮤니티 내 댓글 목록 parser
const myBoardCommentParser = (data: GetMyBoardCommentQuery) => {
  return data.myBoardCommentCursorPage.elements.map((comment) => {
    return {
      ...comment,
      formattedCreatedAt: displayedAt(comment.createdAt),
    };
  });
};

/**
 * BFF Service
 */

// 카테고리
export const boardCategoriesService = (data: GetBoardCategoriesQuery): Array<BoardCategory> => {
  return boardCategoriesParser(data);
};

// 카테고리 바텀시트에서 사용될 서비스
export const boardCategoriesBottomSheetService = (
  data: GetBoardCategoriesQuery,
): Array<CommunityBottomSheetCategoriesDto> => {
  return boardCategoriesBottomSheetParser(data);
};

// 홈 인기/추천 글 목록
export const latestHotBoardPostsService = (data: GetRecommendedPopularBoardPostsQuery): Array<LatestHotBoardPosts> => {
  return latestHotBoardPostsParser(data);
};

// 커뮤니티 글 목록 service
export const newestBoardPostService = (
  data: Array<GetNewestBoardPostCursorPageQuery>,
  isCategoryFilter?: boolean,
): Array<BoardPostVo> => {
  if (data) {
    return newestBoardPostParser(
      {
        newestBoardPostCursorPage: {
          elements: data.flatMap((page) => page.newestBoardPostCursorPage.elements),
          pivot: data[data.length - 1].newestBoardPostCursorPage.pivot,
        },
      },
      isCategoryFilter,
    );
  }
  return [] as Array<BoardPostVo>;
};

// 커뮤니티 내 글 목록 service
export const myBoardPostService = (
  data: Array<GetMyBoardPostCursorPageQuery>,
  isCategoryFilter?: boolean,
): Array<BoardPostVo> => {
  if (data) {
    return myBoardPostParser(
      {
        myBoardPostCursorPage: {
          elements: data.flatMap((page) => page.myBoardPostCursorPage.elements),
          pivot: data[data.length - 1].myBoardPostCursorPage.pivot,
        },
      },
      isCategoryFilter,
    );
  }
  return [] as Array<BoardPostVo>;
};

// 커뮤니티 글 상세페이지 service
export const boardPostDetailService = (data: GetBoardPostQuery): BoardPostDetail => {
  return boardPostDetailParser(data);
};

// 커뮤니티 내 댓글 목록 service
export const myBoardCommentService = (data: Array<GetMyBoardCommentQuery>): Array<MyBoardComment> => {
  if (data) {
    return myBoardCommentParser({
      myBoardCommentCursorPage: {
        elements: data.flatMap((page) => page.myBoardCommentCursorPage.elements),
        pivot: data[data.length - 1].myBoardCommentCursorPage.pivot,
      },
    });
  }
  return [] as Array<MyBoardComment>;
};

const getCommentsbyCommentParser = (data: GetBoardCommentBelongedBoardCommentsQuery) => {
  return data.boardCommentBelongedBoardComments.map((comment) => {
    return {
      ...comment,
      formattedCreatedAt: displayedAt(comment.createdAt),
    };
  });
};

export const getCommentsbyCommentService = (data: GetBoardCommentBelongedBoardCommentsQuery): Array<BoardComment> => {
  return getCommentsbyCommentParser(data);
};

const communityUserProfileParser = (data: GetCommunityUserQuery): CommunityUserProfile => {
  return {
    nickname: data.communityUser.nickname,
    profileImageUrl: data.communityUser.profileImageUrl,
  };
};

export const communityUserProfileService = (data: GetCommunityUserQuery): CommunityUserProfile => {
  return communityUserProfileParser(data);
};

const communityUserParser = (data: GetCommunityUserQuery): CommunityUser => {
  return {
    ...data,
    nickname: data.communityUser.nickname,
    userId: data.communityUser.userId,
    tutorialCompleted: data.communityUser.tutorialCompleted,
    profileImageUrl: data.communityUser.profileImageUrl,
    isAdmin: data.communityUser.isAdmin,
    bannedUntil: data.communityUser.bannedUntil ?? undefined,
    formattedBannedDate: data.communityUser.bannedUntil
      ? dayjs(data.communityUser.bannedUntil).format('YYYY년 MM월 DD일 HH시까지')
      : undefined,
    isBanned: dayjs().isBefore(data.communityUser.bannedUntil),
  };
};

export const communityUserService = (data: GetCommunityUserQuery): CommunityUser => {
  return communityUserParser(data);
};
